@charset "utf-8";

@font-face {
	font-family:'RobotoL';
	font-style: normal;
	font-weight: 300;
	src: url('../../font/Roboto-Light.eot');
	src: url('../../font/Roboto-Light.eot?#iefix') format('embedded-opentype'),
		 url('../../font/Roboto-Light.woff2') format('woff2'),
		 url('../../font/Roboto-Light.woff') format('woff'),
		 url('../../font/Roboto-Light.ttf') format('truetype');
}
@font-face {
	font-family:'RobotoR';
	font-style: normal;
	font-weight: 400;
	src: url('../../font/Roboto-Regular.eot');
	src: url('../../font/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
		 url('../../font/Roboto-Regular.woff2') format('woff2'),
		 url('../../font/Roboto-Regular.woff') format('woff'),
		 url('../../font/Roboto-Regular.ttf') format('truetype');
}
@font-face {
	font-family:'RobotoM';
	font-style: normal;
	font-weight: 500;
	src: url('../../font/Roboto-Medium.eot');
	src: url('../../font/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
		 url('../../font/Roboto-Medium.woff2') format('woff2'),
		 url('../../font/Roboto-Medium.woff') format('woff'),
		 url('../../font/Roboto-Medium.ttf') format('truetype');
}
@font-face {
	font-family:'RobotoB';
	font-style: normal;
	font-weight: 700;
	src: url('../../font/Roboto-Bold.eot');
	src: url('../../font/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
		 url('../../font/Roboto-Bold.woff2') format('woff2'),
		 url('../../font/Roboto-Bold.woff') format('woff'),
		 url('../../font/Roboto-Bold.ttf') format('truetype');
}