@charset "utf-8";

@font-face {
	font-family:'NotoKrT';
	font-style: normal;
	font-weight: 100;
	src: url('../../font/notokr-thin.eot');
	src: url('../../font/notokr-thin.eot?#iefix') format('embedded-opentype'),
		 url('../../font/notokr-thin.woff2') format('woff2'),
		 url('../../font/notokr-thin.woff') format('woff'),
		 url('../../font/NotoKR-Thin.ttf') format('opentype');
}
@font-face {
	font-family:'NotoKrL';
	font-style: normal;
	font-weight: 300;
	src: url('../../font/notokr-light.eot');
	src: url('../../font/notokr-light.eot?#iefix') format('embedded-opentype'),
		 url('../../font/notokr-light.woff2') format('woff2'),
		 url('../../font/notokr-light.woff') format('woff'),
		 url('../../font/NotoKR-Light.ttf') format('truetype');
}
@font-face {
	font-family:'NotoKrR';
	font-style: normal;
	font-weight: 400;
	src: url('../../font/notokr-regular.eot');
	src: url('../../font/notokr-regular.eot?#iefix') format('embedded-opentype'),
		 url('../../font/notokr-regular.woff2') format('woff2'),
		 url('../../font/notokr-regular.woff') format('woff'),
		 url('../../font/NotoKR-Regular.ttf') format('truetype');
}
@font-face {
	font-family:'NotoKrM';
	font-style: normal;
	font-weight: 500;
	src: url('../../font/notokr-medium.eot');
	src: url('../../font/notokr-medium.eot?#iefix') format('embedded-opentype'),
		 url('../../font/notokr-medium.woff2') format('woff2'),
		 url('../../font/notokr-medium.woff') format('woff'),
		 url('../../font/NotoKR-Medium.ttf') format('truetype');
}
@font-face {
	font-family:'NotoKrB';
	font-style: normal;
	font-weight: 700;
	src: url('../../font/notokr-bold.eot');
	src: url('../../font/notokr-bold.eot?#iefix') format('embedded-opentype'),
		 url('../../font/notokr-bold.woff2') format('woff2'),
		 url('../../font/notokr-bold.woff') format('woff'),
		 url('../../font/NotoKR-Bold.ttf') format('truetype');
}